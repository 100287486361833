<template>
    <el-dialog v-if="addVisible" title="新增考勤方案" width="600px" :visible.sync="addVisible" :before-close="closeEvent">
        <el-form ref="form" label-width="100px" style="margin-top: -10px">
            <el-form-item label="考勤名称:" required>
                <el-input size="small" v-model="csName" maxlength="64" show-word-limit style="width: 280px;"></el-input>
            </el-form-item>
            <el-form-item label="打卡设置:" required>
             <div style="display: flex;flex-direction: column;width: 280px">
               <el-select v-model="csMonday" placeholder="请选择周一班次" size="small">
                 <el-option
                   v-for="item in oneList"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
                 </el-option>
               </el-select>
               <el-select v-model="csTuesday" placeholder="请选择周二班次" size="small">
                 <el-option
                   v-for="item in oneList"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
                 </el-option>
               </el-select>
               <el-select v-model="csWednesday" placeholder="请选择周三班次" size="small">
                 <el-option
                   v-for="item in oneList"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
                 </el-option>
               </el-select>
               <el-select v-model="csThursday" placeholder="请选择周四班次" size="small">
                 <el-option
                   v-for="item in oneList"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
                 </el-option>
               </el-select>
               <el-select v-model="csFriday" placeholder="请选择周五班次" size="small">
                 <el-option
                   v-for="item in oneList"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
                 </el-option>
               </el-select>
               <el-select v-model="csSaturday" placeholder="请选择周六班次" size="small">
                 <el-option
                   v-for="item in twoList"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
                 </el-option>
               </el-select>
               <el-select v-model="csSunday" placeholder="请选择周日班次" size="small">
                 <el-option
                   v-for="item in twoList"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
                 </el-option>
               </el-select>
             </div>
            </el-form-item>
<!--          <div style="margin-bottom: 8px">-->
<!--            迟到或早退积分设置:-->
<!--          </div>-->
<!--          <el-form-item label="" required>-->
<!--            <div style="display: flex;flex-direction: column;">-->
<!--              <div>-->
<!--                迟到或早退-->
<!--                <el-input size="small" v-model="witMin" style="width: 88px;"></el-input>-->
<!--                分钟内不记积分-->
<!--              </div>-->
<!--              <div>-->
<!--                迟到或早退-->
<!--                <el-input size="small" v-model="staMin" style="width: 88px;"></el-input>-->
<!--                至-->
<!--                <el-input size="small" v-model="endMin" style="width: 88px;"></el-input>-->
<!--                分钟记-->
<!--                <el-input size="small" v-model="betIntegral" style="width: 88px;"></el-input>-->
<!--                积分-->
<!--              </div>-->
<!--              <div>-->
<!--                迟到或早退-->
<!--                <el-input size="small" v-model="outMin" style="width: 88px;"></el-input>-->
<!--                分钟以上记-->
<!--                <el-input size="small" v-model="outIntegral" style="width: 88px;"></el-input>-->
<!--                积分每分钟-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-form-item>-->
        </el-form>
        <div class="card-footer" style="width: 535px;">
            <el-button size="small" @click="closeEvent">取 消</el-button>
            <el-button size="small" type="primary" @click="saveBbiAd">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>

    export default {
        data() {
            return {
              csName:'',
              csMonday:'',
              csTuesday:'',
              csWednesday:'',
              csThursday:'',
              csFriday:'',
              csSaturday:'',
              csSunday:'',
              witMin:'',
              staMin:'',
              endMin:'',
              betIntegral:'',
              outMin:'',
              outIntegral:'',
              oneList:[],
              twoList:[],
            }
        },
        props: {
            addVisible: Boolean
        },
        async created() {
           await this.getTime()
        },
        methods: {
            async getTime() {
                let res = await this.$get('/admin/getShiftTimeList')
                this.oneList = res.oneList;
                this.twoList = res.twoList;
            },

            closeEvent() {
                this.$emit('closeInsert')
            },
            async saveBbiAd() {
                let data = {
                  csName:this.csName,
                  csMonday:this.csMonday,
                  csTuesday:this.csTuesday,
                  csWednesday:this.csWednesday,
                  csThursday:this.csThursday,
                  csFriday:this.csFriday,
                  csSaturday:this.csSaturday,
                  csSunday:this.csSunday,
                  // witMin:this.witMin,
                  // staMin:this.staMin,
                  // endMin:this.endMin,
                  // betIntegral:this.betIntegral,
                  // outMin:this.outMin,
                  // outIntegral:this.outIntegral
                };
                if (!this.csName) {
                    this.$message.error("请输入考勤方案名称");
                    return
                }
                if (!this.csMonday) {
                  this.$message.error("请选择周一班次!");
                  return
                }
                if (!this.csTuesday) {
                  this.$message.error("请选择周二班次!");
                  return
                }
                if (!this.csWednesday) {
                  this.$message.error("请选择周三班次!");
                  return
                }
                if (!this.csThursday) {
                  this.$message.error("请选择周四班次!");
                  return
                }
                if (!this.csFriday) {
                  this.$message.error("请选择周五班次!");
                  return
                }
                if (!this.csSaturday) {
                  this.$message.error("请选择周六班次!");
                  return
                }
                if (!this.csSunday) {
                  this.$message.error("请选择周日班次!");
                  return
                }
                let res = await this.$post("/admin/saveShift", data);
                if (res.code == 200) {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'success'
                    });
                    this.$emit('addSuccess')
                }
            }
        }
    }
</script>
<style>
    .e-file-upload .e-upload--text{
        width: 137px;
        height: 137px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center
    }
    .e-file-upload .e-upload--text .e-upload--icon{
        margin-top: 40px;
        font-size: 25px;
        color: rgb(0,133,215);
    }
</style>
