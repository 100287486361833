<template>
    <div>
        <div class="e-breadcrumb">考勤方案</div>
        <div class="order-table-wrapper">
            <div class="e-card">
                <div class="e-card-body" style="padding-top:35px">
                    <div class="e-handle-box" style="margin-bottom: 50px">
                        <div class="handle-box">
                            <span class="v-form-label">考勤方案名称:</span>
                            <el-input placeholder="考勤方案名称" size="small" style="width:265px" v-model="csName" clearable
                                      class="handle-input mr10"></el-input>
                            <div style="margin-left:  128px">
                                <el-row type="flex" justify="end">
                                    <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
                                    </el-button>
                                    <el-button size="small" class="e-button" type="success" @click="addTimeEvent()">设置班次
                                    </el-button>
                                    <el-button size="small" class="e-button" type="success" @click="addEvent()">新增考勤方案
                                    </el-button>
                                </el-row>
                            </div>
                        </div>
                    </div>
                    <br/> <br/>

                    <div class="e-table-wrapper">
                        <table cellspacing="0" cellpadding="0" border="1" width="100%" style="text-align: center">
                            <thead>
                            <tr>
                              <th  class="th-l" colspan="1" rowspan="2">考勤方案名称</th>
                              <th  class="th-l" colspan="4" >周一至周五</th>
                              <th  class="th-l" colspan="4" >周六至周日</th>
                              <th  class="th-l" colspan="1" rowspan="2">操作</th>
                            </tr>
                            <tr>
                              <th >早班</th>
                              <th >中班</th>
                              <th >晚班</th>
                              <th >下班</th>
                              <th >早班</th>
                              <th >中班</th>
                              <th >晚班</th>
                              <th >下班</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(row,index) in list" :key="row.id">
                                <td class="td-l"><span>{{row.csName}}</span></td>
                                <td class="td-l">
                                  {{oneItem.morningShift}}
                                </td>
                                <td class="td-l">
                                  {{oneItem.middleShift}}
                                </td>
                                <td class="td-l">
                                  {{oneItem.eveningShift}}
                                </td>
                                <td class="td-l">
                                  {{oneItem.rest}}
                                </td>
                                <td class="td-l">
                                  {{twoItem.morningShift}}
                                </td>
                                <td class="td-l">
                                  {{twoItem.middleShift}}
                                </td>
                                <td class="td-l">
                                  {{twoItem.eveningShift}}
                                </td>
                                <td class="td-l">
                                  {{twoItem.rest}}
                                </td>

                                <td class="td-l" style="width: 160px">
                                    <el-button type="text" icon="el-icon-edit" @click="editEvent(index)">编辑
                                    </el-button>
                                    <el-button type="text" icon="el-icon-delete" class="red"
                                               @click="removeEvent(index)">删除
                                    </el-button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="pagination">
                            <el-pagination
                                    background
                                    @current-change="handleCurrentChange"
                                    :pager-count="7"
                                    layout="total, prev, pager, next,jumper"
                                    :total="pagination.count"
                                    :page-count="pagination.pages"
                                    :current-page="pagination.pageNumber"
                            ></el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Insert v-if="addVisible" :addVisible="addVisible" @closeInsert="closeInsert" @addSuccess="addSuccess"/>
        <Edit v-if="editVisible" :editVisible="editVisible" @closeEdit="closeEdit" @editSuccess="editSuccess"
              :editItem="editItem"/>
       <AddTime v-if="addTimeVisible" :addTimeVisible="addTimeVisible" @closeAddTime="closeAddTime" @addTimeSuccess="addTimeSuccess"/>


        <!-- 删除提示框 -->
        <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
            <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delShift">确 定</el-button>
            </span>
        </el-dialog>


    </div>
</template>

<script>
    import AddTime from './addTime'
    import Insert from './insert'
    import Edit from './edit'

    export default {
        data() {
            return {
                csName: '',
                list: [],
                pagination: {
                    count: 0,
                    pageNumber: 0,
                    pageSize: 0,
                    pages: 1
                },
                oneItem:{},
                twoItem:{},
                addTimeVisible: false,

                addVisible: false,
                editVisible: false,
                delVisible: false,

                selItem: {},
                editItem: {},

            }
        },
        async created() {
            this.searchEvent();
        },
        methods: {

          addTimeEvent(){
            this.addTimeVisible= true
          },

            handleCurrentChange(page) {
                this.requestSearch(page)
            },

            searchEvent() {
                this.requestSearch()
            },
            async requestSearch(page) {
                let currentPage = page || 1;
                let data = {
                    csName: this.csName,
                    size: this.size == '' ? '10' : this.size,
                    currentPage
                }
                let dataSet = await this.$get("/admin/getShiftForPage", data)
                let res = dataSet.dataSet;
                let list = res.list
                this.list = list;
                this.oneItem=dataSet.one
                this.twoItem=dataSet.two
                this.pagination = {
                    count: res.count,
                    pageNumber: res.pageNumber,
                    pageSize: res.pageSize,
                    pages: res.pages
                }
            },

            addEvent() {
                this.addVisible = true;
            },

            editEvent(index) {
                this.editItem = this.list[index]
              if (this.editItem.betMin){
                let arr = this.editItem.betMin.split("-")
                this.editItem['staMin']=arr[0]
                this.editItem['endMin']=arr[1]
              }else {
                this.editItem['staMin']=''
                this.editItem['endMin']=''
              }
                this.editVisible = true;
            },

            async removeEvent(index) {
                this.selItem = this.list[index]
                this.delVisible = true;
            },

            //删除
            async delShift() {
                let data = {
                    id: this.selItem.id
                };
                let res = await this.$post("/admin/removeShift", data)
                if (res.code == 200) {
                    this.searchEvent();
                    this.$message.success(res.message);
                    this.delVisible = false;
                } else {
                  this.$message.error(res.message);
                }
            },

            closeAddTime() {
                this.addTimeVisible = false;
            },
            addTimeSuccess() {
            this.closeAddTime();
           },

            closeInsert() {
                this.addVisible = false;
            },

            closeEdit() {
                this.editVisible = false;
            },

            addSuccess() {
                this.closeInsert();
                this.searchEvent();
            },

            editSuccess() {
                this.closeEdit();
                this.searchEvent();
            }
        },
        components: {
            Insert,
            Edit,
            AddTime,
        }
    }

</script>

<style scoped>
    .th-l {
        text-align: center;
        padding-right: 10px;
        vertical-align: middle;
    }
    .td-l {
        padding: 10px 12px;
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        border: 1px #CCCCCC solid;
    }

  .e-table-wrapper thead th{
    padding-left: 10px;
    font-weight: 700;
    font-size: 12px;
    color: #000;
    line-height: 40px;
    text-align: center;
    border: 1px #CCCCCC solid;
  }
</style>
