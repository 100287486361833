var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.addTimeVisible)?_c('el-dialog',{attrs:{"title":"设置班次","width":"460px","visible":_vm.addTimeVisible,"before-close":_vm.closeEvent},on:{"update:visible":function($event){_vm.addTimeVisible=$event}}},[_c('el-form',{ref:"form",staticStyle:{"margin-top":"-10px"},attrs:{"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"选择类型:","required":""}},[_c('el-select',{staticStyle:{"width":"260px"},attrs:{"size":"small","placeholder":"请选择"},on:{"change":function($event){return _vm.setItem(_vm.stType)}},model:{value:(_vm.stType),callback:function ($$v) {_vm.stType=$$v},expression:"stType"}},_vm._l((_vm.typeList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"早班时间:","required":""}},[_c('el-time-select',{staticStyle:{"width":"260px"},attrs:{"size":"small","picker-options":{
            start: '00:00',
            step: '00:30',
            end: '23:30'
          },"placeholder":"选择时间"},model:{value:(_vm.morningShift),callback:function ($$v) {_vm.morningShift=$$v},expression:"morningShift"}})],1),_c('el-form-item',{attrs:{"label":"中班时间:","required":""}},[_c('el-time-select',{staticStyle:{"width":"260px"},attrs:{"size":"small","picker-options":{
            start: '00:00',
            step: '00:30',
            end: '23:30'
          },"placeholder":"选择时间"},model:{value:(_vm.middleShift),callback:function ($$v) {_vm.middleShift=$$v},expression:"middleShift"}})],1),_c('el-form-item',{attrs:{"label":"晚班时间:","required":""}},[_c('el-time-select',{staticStyle:{"width":"260px"},attrs:{"size":"small","picker-options":{
            start: '00:00',
            step: '00:30',
            end: '23:30'
          },"placeholder":"选择时间"},model:{value:(_vm.eveningShift),callback:function ($$v) {_vm.eveningShift=$$v},expression:"eveningShift"}})],1),_c('el-form-item',{attrs:{"label":"下班时间:","required":""}},[_c('el-time-select',{staticStyle:{"width":"260px"},attrs:{"size":"small","picker-options":{
            start: '00:00',
            step: '00:30',
            end: '23:30'
          },"placeholder":"选择时间"},model:{value:(_vm.rest),callback:function ($$v) {_vm.rest=$$v},expression:"rest"}})],1)],1),_c('div',{staticClass:"card-footer",staticStyle:{"width":"450px"}},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.closeEvent}},[_vm._v("取 消")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.saveTime}},[_vm._v("确 定")])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }