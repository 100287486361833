<template>
    <el-dialog v-if="addTimeVisible" title="设置班次" width="460px" :visible.sync="addTimeVisible" :before-close="closeEvent">
        <el-form ref="form" label-width="100px" style="margin-top: -10px">
            <el-form-item label="选择类型:" required>
                <el-select size="small" v-model="stType" placeholder="请选择" @change="setItem(stType)" style="width: 260px">
                    <el-option
                            v-for="item in typeList"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
          <el-form-item label="早班时间:" required>
            <el-time-select
              size="small"
              style="width: 260px"
              v-model="morningShift"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30'
              }"
              placeholder="选择时间">
            </el-time-select>
          </el-form-item>
          <el-form-item label="中班时间:" required>
            <el-time-select
              size="small"
              style="width: 260px"
              v-model="middleShift"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30'
              }"
              placeholder="选择时间">
            </el-time-select>
          </el-form-item>
          <el-form-item label="晚班时间:" required>
            <el-time-select
              size="small"
              style="width: 260px"
              v-model="eveningShift"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30'
              }"
              placeholder="选择时间">
            </el-time-select>
          </el-form-item>
          <el-form-item label="下班时间:" required>
            <el-time-select
              size="small"
              style="width: 260px"
              v-model="rest"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30'
              }"
              placeholder="选择时间">
            </el-time-select>
          </el-form-item>
        </el-form>
        <div class="card-footer" style="width: 450px;">
            <el-button size="small" @click="closeEvent">取 消</el-button>
            <el-button size="small" type="primary" @click="saveTime">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    export default {
        data() {
            return {
              id:'',
              stType:'',
              morningShift:'',
              middleShift:'',
              eveningShift:'',
              rest:'',
              oneItem:{},
              twoItem:{},

              typeList: [
                {
                  name:'周一至周五',
                  value:'1',
                },
                {
                  name:'周六至周日',
                  value:'2',
                }
              ],
            }
        },
        props: {
            addTimeVisible: Boolean
        },
        async created() {
          this.getTime()
        },
        methods: {
            closeEvent() {
                this.$emit('closeAddTime')
            },
          async getTime(){
            let res = await this.$get("/admin/getShiftTime");
            this.oneItem=res.one
            this.twoItem=res.two
           },
          setItem(stType){
              this.morningShift=''
              this.middleShift=''
              this.eveningShift=''
              this.rest=''
              this.id=''
              if (stType==1&&this.oneItem){
                 this.morningShift=this.oneItem.morningShift
                 this.middleShift=this.oneItem.middleShift
                 this.eveningShift=this.oneItem.eveningShift
                 this.rest=this.oneItem.rest
                 this.id=this.oneItem.id
              }
              if (stType==2&&this.twoItem){
                this.morningShift=this.twoItem.morningShift
                this.middleShift=this.twoItem.middleShift
                this.eveningShift=this.twoItem.eveningShift
                this.rest=this.twoItem.rest
                this.id=this.twoItem.id
              }
          },
            async saveTime() {
                let data = {
                    id:this.id,
                    stType: this.stType,
                    morningShift: this.morningShift,
                    middleShift: this.middleShift,
                    eveningShift: this.eveningShift,
                    rest: this.rest,
                };
                if (!this.stType) {
                    this.$message.error("请选择类型!");
                    return
                }
                let res = await this.$post("/admin/saveShiftTime", data);
                if (res.code == 200) {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'success'
                    });
                    this.$emit('addTimeSuccess')
                }
            }
        }
    }
</script>
<style>
    .e-file-upload .e-upload--text{
        width: 137px;
        height: 137px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center
    }
    .e-file-upload .e-upload--text .e-upload--icon{
        margin-top: 40px;
        font-size: 25px;
        color: rgb(0,133,215);
    }
</style>
